<template>
  <v-container class="grid-list-md" >
    <v-row>
      <v-col cols="12">
        <h1 class="display-1 white--text full-width fw-1 text-center text-lg-left text-uppercase no-select">IL MIO CARRELLO</h1>
      </v-col>
    </v-row>
    <v-divider dark class="mb-4 mt-4"/>
    <v-progress-linear indeterminate v-if="loading" color="accent" rounded></v-progress-linear>
    <v-row v-if="!voidCart">
      <v-col cols="12" :sm="cartItemsLength > 1 ? 6 : 12" :lg="cartItemsLength > 2 ? 4 : 6" :xl="cartItemsLength > 3 ? 3 : 4" v-for="item in cart.items" :key="item._id">
        <v-card @click="onItemSelect(item)">
          <v-img :aspect-ratio="16/9" :src="coverUrl(item)" >
            <div class="d-flex full-width justify-space-between">
              <v-chip v-if="!isHomeKit(item) && !isSeats(item)" class="no-select text-uppercase fw-6 ma-3" :color="difficultyColor(item)">{{difficultyName(item)}}</v-chip>
              <div v-else></div>
              <v-btn class="ma-3" icon color="accent" @click="remove(item)" ><v-icon>mdi-close</v-icon></v-btn>
            </div>
          </v-img>
          <v-card-text>
            <div class="d-flex">
              <h3 class="no-select title d-inline-flex">{{getName(item)}}</h3>
            </div>
            <p class="no-select subtitle-1 mb-0 accent--text font-weight-bold text-uppercase">{{categoryName(item)}}</p>
            <p class="no-select subtitle-1 mb-0">{{getPrice(item).toFixed(2)}} €<span v-if="!isSeatsProduct(item) && (isHomeKit(item) || !profile || profile.user_type === 'commercial')"> iva inclusa</span></p>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" :sm="cartItemsLength > 1 ? 6 : 12" :lg="cartItemsLength > 2 ? 4 : 6" :xl="cartItemsLength > 3 ? 3 : 4" v-if="cart.sub_pause_days">
        <v-card>
          <v-img :aspect-ratio="16/9" src="../assets/logo.png" >
            <div class="d-flex full-width justify-end">
              <v-btn class="ma-3" icon color="accent" @click="removeSubDays()" ><v-icon>mdi-close</v-icon></v-btn>
            </div>
          </v-img>
          <v-card-text>
            <div class="d-flex">
              <h3 class="no-select title d-inline-flex">{{cart.sub_pause_days}} giorni extra di pausa abbonamento</h3>
            </div>
            <p class="no-select subtitle-1 mb-0">{{subPausePrice.toFixed(2)}} € iva inclusa</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" >
        <p class="no-select subtitle-1 mb-0 font-italic white--text d-inline-flex align-center">non ci sono elementi nel carrello, <v-btn class="d-inline-flex pa-1" rounded text color="accent" @click="$router.push('/')"><span class="underline tolower no-select">cercane uno</span> <v-icon>mdi-magnify</v-icon></v-btn></p>
      </v-col>
    </v-row>
    <v-divider dark class="mb-4 mt-4"/>

    <v-row v-if="!noMspItems && needsCard && !homeKitAsProduct">
      <v-col cols="12" >
        <p class="no-select subtitle-1 mb-0 white--text d-inline-flex align-center">Tesseramento MSP Italia (obbligatorio): {{coniCardPrice.toFixed(2)}}€</p>
      </v-col>
    </v-row>
    <v-row v-if="!voidCart && needsExpedition">
      <v-col cols="12" >
        <v-radio-group dark v-model="cart.expedition" @change="saveCart()">
          <v-radio class="white--text" :label="'Spedizione fra '+settings.home_kit_expedition_days+' giorni lavorativi*: '+settings.home_kit_expedition_price.toFixed(2)+'€'" :value="true"></v-radio>
          <v-radio class="white--text" :label="'Ritiro in sede presso Piazzale Kennedy n. 1/B – 06012, Città di Castello (PG) fra '+settings.home_kit_expedition_days+' giorni lavorativi: '" :value="false"></v-radio>
        </v-radio-group>
        <span class="caption white--text no-select">*il giorno di arrivo del pacco potrebbe variare a seconda della tipologia di spedizione e della disponibilità del corriere</span>
      </v-col>
    </v-row>
    <v-divider dark class="mb-4 mt-4" v-if="!voidCart && needsExpedition"/>
    <v-row v-if="!voidCart">
      <v-col cols="6" class="d-flex">
        <h1 class="display-1 white--text fw-1 text-uppercase no-select">TOTALE</h1>
      </v-col>
      <v-col cols="6" class="d-flex justify-end">
        <h1 class="display-1 accent--text fw-4 text-uppercase no-select">{{total.toFixed(2)}}€<span v-if="!hasSeatsProduct && (!profile || profile.user_type === 'commercial')"> iva inclusa</span></h1>
      </v-col>
      <v-col cols="12" class="d-flex justify-end" v-if="!voidCart">
        <v-btn class="flex-grow-1 flex-shrink-0 flex-md-grow-0 flex-md-shrink-1" rounded color="accent" @click="checkout()">Acquista!</v-btn>
      </v-col>
    </v-row>


  </v-container>

</template>

<script>
import {mapGetters, mapActions, mapState } from 'vuex'
import config from "../config";
export default {
  name: "Cart",
  data () {
    return {
      stopPropagation : false,
      loading : false,
    }
  },
  computed : {
    ...mapGetters(['token', 'profile','bgImage','products', 'purchases','categories','genre','difficulties','cart','settings','userSeats']),
    cartItemsLength() {
      let num = this.cart.sub_pause_days > 0 ? 1 : 0;
      if(this.cart && this.cart.items) {
        num+= this.cart.items.length;
      }
      return num;
    },
    loggedIn() {
      return this.profile != null
    },
    coniCardPrice(){
      return this.settings.coni_card_price;
    },
    numSeats() {
      return this.userSeats.num_seats - this.userSeats.num_booked;
    },
    homeKitAsProduct() {
      let numKitProducts = 0;
      for(let i = 0; i < this.cart.items; i++) {
        const itemId = this.cart.items[i];
        const product = this.getProduct(itemId);
        if(product.sku === config.homeKitSkuF || product.sku === config.homeKitSkuM) {
          numKitProducts++
        }
      }
      console.log("numKitProducts === this.cart.items.length", numKitProducts === this.cart.items.length)

      return numKitProducts > 0 && numKitProducts === this.cart.items.length;
    },
    notLoggedDialog: {
      set(newVal) {  this.$store.state.notLoggedDialog = newVal },
      get() { return this.$store.state.notLoggedDialog }
    },
    needsExpedition() {
      for(let i = 0; i < this.cart.items.length; i++) {
        const p = this.getProduct(this.cart.items[i]);
        if(p && (p.sku === config.homeKitSkuF || p.sku === config.homeKitSkuM || (p.expedition && this.profile && !this.profile.home_kit))) {
          return true;
        }
      }
      return false
    },
    hasSeatsProduct() {
      for(let i = 0; i < this.cart.items.length; i++) {
        const p = this.getProduct(this.cart.items[i]);
        if(p && p.is_seats_product) {
          return true;
        }
      }
      return false
    },
    needsCard()  {
      console.log(this.profile)
      return this.profile && this.profile.user_type === 'card' && !this.profile.has_valid_card;
    },
    noMspItems() {
      if(!this.cart) return true;
      return !this.cart.items || !this.cart.items.length;
    },
    voidCart() {
      if(!this.cart) return true;
      return (!this.cart.items || !this.cart.items.length) && !this.cart.sub_pause_days;
    },
    subPausePrice() {
      if(this.cart) {
        return this.cart.sub_pause_days * this.settings.extra_day_of_sub_pause_price;
      }
      return 0
    },
    total() {
      let total = 0;
      for(let i = 0; i < this.cart.items.length; i++) {
        total += this.getPrice(this.cart.items[i]);
      }
      if(this.needsCard && !this.noMspItems) {
        total += this.settings.coni_card_price;
      }
      if(this.needsExpedition && this.cart.expedition) {
        total += this.settings.home_kit_expedition_price;
      }
      total += this.subPausePrice;
      return total;
    },
  },
  methods: {
    ...mapActions(['logout', 'load', 'sendError','refreshCollection','requestConfirm','onScroll','refreshCollectionPromise','removeFromCart','saveUserCart','setPauseDaysToCart']),
    coverUrl(id) {
      const p = this.getProduct(id);
      if(p) {
        return p.kit.cover.url
      }
      return '';
    },
    isHomeKit(id){
      const p = this.getProduct(id);
      return p && (p.sku === config.homeKitSkuF || p.sku === config.homeKitSkuM);
    },
    isSeatsProduct(id){
      const p = this.getProduct(id);
      return p.is_seats_product;
    },
    isSeats(id){
      const p = this.getProduct(id);
      return p.is_seats_product;
    },
    getName(id) {
      const p = this.getProduct(id);
      if(p) {
        if(p.sku === config.seatSku) {
          if(this.numSeats < 0) {
            return `${p.kit.title.it} - ${this.cart.num_seats + this.numSeats} x ${p.title.it} (+ ${Math.abs(this.numSeats)} in sospeso)`
          }

          return `${p.kit.title.it} - ${this.cart.num_seats} x ${p.title.it}`
        }
        return `${p.kit.title.it} - ${p.title.it}`;
      }
      return 0;
    },
    isPurchased(product) {
      let filter = this.purchases.filter((item) => item.product._id === product._id)
      return filter.length > 0;
    },
    getPrice(id) {
      const p = this.getProduct(id);
      if(p) {
        let price = p.price;

        if(p.sku === config.seatSku) {
          price *= this.cart.num_seats
          return price
        }
        if(p.is_seats_product) {
          return price
        }

        if(p.second_price && this.isPurchased(p)) {
          price = p.second_price;
        }
        if(!this.isHomeKit(id) && this.profile && this.profile.user_type !== 'commercial')
          return price;
        const iva = price / 100 * config.iva;
        return (price + iva);
      }
      return 0;
    },
    categoryName(id) {
      const p = this.getProduct(id);
      if(p) {
        return p.kit.category.name.it
      }
      return '';
    },
    difficultyName(id) {
      const p = this.getProduct(id);
      if(p) {
        return p.kit.difficulty.name.it
      }
      return '';
    },
    difficultyColor(id) {
      const p = this.getProduct(id);
      if(p) {
        switch (p.kit.difficulty.value) {
          case 1:
            return 'success'
          case 2:
            return 'lime'
          case 3:
            return 'warning'
          case 4:
            return 'error'
        }
      }

      return 'white'
    },
    checkout() {
      if(!this.loggedIn) {
        this.notLoggedDialog = true;
        return
      }
      this.$router.push('/checkout')
    },
    onItemSelect(item) {
      if(this.stopPropagation) {
        this.stopPropagation = false;
        return
      }
      const p = this.getProduct(item);
      if(!p) return;
      if(p.is_seats_product) this.$router.push('/gym/' + p._id);
      else this.$router.push('/products/' + p.kit._id);
    },
    remove(id) {
      this.stopPropagation = true;
      this.requestConfirm({ title : 'Conferma', message : 'Vuoi rimuovere questo elemento dal carrello?', callback: ( accepted )=>{
          if(accepted) {
            this.removeFromCart({id})
            this.saveUserCart();
          }
        }});

    },
    getKit(id) {
      for(let i = 0; i < this.kits.length; i++) {
        if(this.kits[i]._id === id) return this.kits[i];
      }
      return null
    },
    getProduct(id) {
      for(let i = 0; i < this.products.length; i++) {
        if(this.products[i]._id === id) return this.products[i];
      }
      return null
    },
    saveCart() {
      setTimeout(()=>{
        this.saveUserCart();
      }, 500);
    },
    removeSubDays() {
      this.stopPropagation = true;
      this.requestConfirm({ title : 'Conferma', message : 'Vuoi rimuovere questo elemento dal carrello?', callback: ( accepted )=>{
          if(accepted) {
            this.setPauseDaysToCart({days:0})
            this.saveUserCart();
          }
        }});
    }
  },
  mounted() {
    this.loading = true;
    this.refreshCollectionPromise({collection: 'products'}).then(()=>{
      this.loading = false;
    });
    /*
    this.refreshCollection({collection: 'products'});
    this.refreshCollection({collection: 'categories'});
    this.refreshCollection({collection: 'genre'});
    this.refreshCollection({collection: 'difficulties'});*/
  }
}
</script>

<style scoped>

</style>